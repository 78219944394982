<template>
    <Overview 
        :moduleName="this.formatModuleName(this.$route.name, this.$i18n, false)"
        :moduleNamePlural="this.formatModuleName(this.$route.name, this.$i18n)"
        :sort="'office.relation.name'"
        itemNameField="office.relation.name"
        :itemService=itemService
        :itemFormRef=itemFormRef
        :modalWidth="'980px'"
        :selectFields="selectFields"
        :showAddButton="false"
    >
        <template #columns>
            <Column field="office.relation.name" :header="$t('overview.name')"></Column>
            <Column field="office.relation.country.name" :header="$t('navigation.countries')"></Column>
            <Column field="invoice_number" :header="$t('accounting.invoice_number')"></Column>
        </template>

        <template #form>
            <FinanceForm ref="ItemForm" :itemData="item" />
        </template>

        <template #modal-footer>
            <button id="nextbutton" type="button" class="btn btn-success" @click.prevent="next">&gt;&ensp;{{$t('form.continue_to_step')}}<span id="nextStep">2</span></button>
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from '@/components/column';
import FinanceForm from '@/components/finance/FinanceForm.vue';
import officeService from '@/services/FinanceSettingService';
import store from '@/store/user';

export default {
    components: {
        Overview,
        Column,
        FinanceForm
    },
    data() {
        return {
            item: {},
            itemService: officeService,
            itemFormRef: null,
            selectFields: [
                "id",
                "office_id",
                "invoice_number",
                {
                    "office": [
                        "id",
                        "relation_id",
                        {
                            "relation": [
                                "id",
                                "name",
                                "country_id",
                                {
                                    "country": [
                                        "id",
                                        "name",
                                        "iso"
                                    ]
                                }
                            ]
                        },
                    ]
                }
                
            ]
        }
    },
    methods: {
        next() {
            let tabs = document.querySelectorAll("#myTab .nav-link");
            let next = false;
            console.log(tabs, 'tab');
            for(let tab of tabs) {
                if(next) {
                    tab.click();
                    document.getElementById("nextStep").innerHTML = Number(tab.innerHTML[0]) + 1;
                    break;
                }
                if(tab.className.includes("active")){
                    next = true;
                }
                
            }
        },
        setInvoiceNumber(data){
            if(data.finance_setting && data.finance_setting.country_code_invoice_number)
                return data.finance_setting.country_code_invoice_number + data.finance_setting.office_code_invoice_number;
            return data.relation.country.iso + (data.id <= 9 ? ('0' + data.id) : (data.id % 100));
        }
    },
    mounted() {
        if(!store.getters.getCompanyHq && !store.getters.canViewInvoices){
            window.location.href = '/';
            return;
        }
        this.itemFormRef = this.$refs.ItemForm;
    }
}
</script>