import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = 'api/finance-setting'

class FinanceSettingService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
    livesearch(query, field, selectFields = ['id']) {
      var params = {
        "lazyEvent": {
          "sortField": 'relation.' + field,
          "sortOrder": 1,
          "searchFrom": "livesearch",
          "searchType": "regex",
          "filters":{
            'global' :{
              "value": query,
              "matchMode":"contains"
            }
          },
          "selectFields": selectFields
        }
      };
      var selectfield = field.split('.');
      if(selectfield.length == 1){
        params.lazyEvent.selectFields.push('relation_id');
        params.lazyEvent.selectFields.push({'relation': ['id', field]});
      } else {
        var selectfieldObject = {}
        selectfieldObject[selectfield[0]] = ['id', selectfield[1]];
        params.lazyEvent.selectFields.push(selectfield[0] + "_id");
        params.lazyEvent.selectFields.push(selectfieldObject);
      }

      params.lazyEvent = JSON.stringify(params.lazyEvent);
      const queryParams = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
      const queryUrl = `?page=1&` + queryParams
      return http.get('api/office' + queryUrl)
    }
    getDefaultCurrency(id){
      return http.get(`/api/office/default-currency/${id}`)
    }
    getDestinations(){
      return http.get('/api/export/destination')
    }
    getAuthURL(data){
      return http.post('/api/export/get-auth-url', data)
    }
}

export default new FinanceSettingService()